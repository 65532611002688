import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex fs-5 flex-column"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "fs-6 text-gray-400" }
const _hoisted_4 = { class: "d-flex fs-5 flex-column" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_popover = _resolveComponent("el-popover")!

  return (Number(_ctx.product_identifier_id) === 0 || _ctx.product_identifier_id === '')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, _toDisplayString(_ctx.brand_article_no), 1),
        _createElementVNode("div", {
          class: "fs-6",
          innerHTML: _ctx.$t('order.colorAndSize', [_ctx.color, _ctx.size])
        }, null, 8, _hoisted_2)
      ]))
    : (_openBlock(), _createBlock(_component_el_popover, {
        key: 1,
        placement: "top",
        width: "auto",
        trigger: "hover"
      }, {
        reference: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, _toDisplayString(_ctx.brand_article_no), 1),
            _createElementVNode("div", {
              class: "fs-6",
              innerHTML: _ctx.$t('order.colorAndSize', [_ctx.color, _ctx.size])
            }, null, 8, _hoisted_5)
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.product_identifier_id), 1)
          ])
        ]),
        _: 1
      }))
}
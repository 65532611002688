/*
 * @Descripttion:
 * @Author: xiang.gao
 * @Date: 2022-05-25 21:58:17
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-01-05 14:20:05
 */
import { NumberOrString } from "../type/common";

export const productItemData: ProductItem = {
  id: "",
  product_identifier_id: "0",
  name: "",
  original_color: "",
  brand_id: 0,
  color: 0,
  country_of_origin: "",
  gtin: "",
  brand_article_no: "",
  merchant_article_no: "",
  source_link: "",
  net_weight: "",
  weight: "",
  package_material: "",
  name_en: "",
  gallery: [],
  official_prices: [],
  __show: {
    brand_id: "",
    size: "",
    color: "",
  },
};

export interface RelationSku {
  id: NumberOrString;
  gallery: Array<ProductItemImage>;
  source_link: string;
  brand: string;
  brand_article_no: string;
  merchant_article_no: string;
  size: string;
  original_color: string;
}

export interface ProductItem {
  id: NumberOrString;
  product_identifier_id: string;
  gallery: Array<ProductItemImage>;
  name_en: string;
  name: string;
  color: NumberOrString;
  brand_id: NumberOrString;
  brand_article_no: string;
  merchant_article_no: string;
  original_color: string;
  net_weight: string;
  weight: string;
  package_material: string;
  gtin: string;
  country_of_origin: string;
  official_prices: Array<ProductItemOfficialPrices>;
  source_link: string;
  __show: ProductItemShow;
}

export interface ProductItemImage {
  id: number | string;
  origin_url: string;
}

export interface ProductItemOfficialPrices {
  currency: string;
  price: string;
  country_iso_2: string;
}

interface HsCodes {
  DE?: string;
  CN?: string;
}

interface ProductItemShow {
  brand_id: string;
  size: string;
  color: string;
}

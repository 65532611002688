
import { defineComponent } from "vue";

export default defineComponent({
  name: "common-sku-info",
  props: {
    color: {
      type: String,
      default: () => {
        return "";
      },
    },
    size: {
      type: String,
      default: () => {
        return "";
      },
    },
    product_identifier_id: {
      type: String,
      default: () => {
        return "0";
      },
    },
    brand_article_no: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  setup() {
    return {};
  },
});

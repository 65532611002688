/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-09-30 10:50:50
 * @LastEditors: GaoXiang0403 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2023-06-21 16:42:22
 */
import { RouteLocationNormalizedLoaded } from "vue-router";
import { TaggingItem } from "../interface/common";
import { BadgeStyle, CommonLanguageType } from "../type/common";
import {
  OrderRequestProgress,
  OrderRequestType,
  InvoiceStatus,
} from "../type/orderRequest";

export const getMerchantOrderProgress = (
  route: RouteLocationNormalizedLoaded
) => {
  let value: string[] = [];
  if (route.path.match("order-request")) {
    value = [
      String(OrderRequestProgress.Draft),
      String(OrderRequestProgress.New),
      String(OrderRequestProgress.Requested),
      String(OrderRequestProgress.Confirmed),
    ];
  } else if (route.path.match("order-processing")) {
    value = [
      String(OrderRequestProgress.Confirmed),
      String(OrderRequestProgress.Shipped),
    ];
  }
  return value;
};

export const getMerchantOrderInvoiceStatus = (
  status: number
): BadgeStyle | undefined => {
  const result = new Map([
    [InvoiceStatus.Pending, BadgeStyle.LightWarning],
    [InvoiceStatus.Checked, BadgeStyle.LightInfo],
    [InvoiceStatus.Verified, BadgeStyle.LightSuccess],
    [InvoiceStatus.Paid, BadgeStyle.LightSuccess],
    [InvoiceStatus.Unpaid, BadgeStyle.LightDanger],
  ]);
  return result.get(status);
};

export const getMerchantOrderRouterPath = (
  route: RouteLocationNormalizedLoaded
) => {
  let routerUrl = "";
  if (route.path.match("order-request")) {
    routerUrl = "/merchant-order/order-request/";
  } else if (route.path.match("order-processing")) {
    routerUrl = "/merchant-order/order-processing/";
  } else {
    routerUrl = "/merchant-order/order-overview/";
  }
  return routerUrl;
};

/**
 * @description: 获取订单需求状态（样式）
 * @return CommonStatus
 */
export const getOrderRequestProgressClass = (status: number): string => {
  let statusClass = "";
  switch (status) {
    case OrderRequestProgress.Draft:
      statusClass = BadgeStyle.LightPrimary; // 蓝
      break;
    case OrderRequestProgress.New:
      statusClass = BadgeStyle.LightWarning; // 黄
      break;
    case OrderRequestProgress.Requested:
      statusClass = BadgeStyle.LightPrimary; // 蓝
      break;
    case OrderRequestProgress.Confirmed:
      statusClass = BadgeStyle.LightSuccess; // 绿
      break;
    case OrderRequestProgress.Shipped:
      statusClass = BadgeStyle.LightInfo; // 紫
      break;
    case OrderRequestProgress.Canceled:
      statusClass = BadgeStyle.LightDark; // 黑
      break;
    default:
      statusClass = BadgeStyle.LightPrimary; // 蓝
  }
  return statusClass;
};

export const getOrderRequestTypeOptions = (
  language: CommonLanguageType
): TaggingItem[] => {
  return [
    {
      label: language("orderRequest.merchantOrder"),
      value: String(OrderRequestType.MerchantOrder),
    },
    {
      label: language("orderRequest.agentPurchaseOrder"),
      value: String(OrderRequestType.AgentPurchaseOrder),
    },
  ];
};

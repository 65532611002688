/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-09-30 10:49:08
 * @LastEditors: GaoXiang0403 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2023-06-21 09:57:36
 */
export enum OrderRequestProgress {
  Draft = 1,
  New = 5,
  Requested = 10,
  Confirmed = 20,
  Shipped = 30,
  Canceled = 40,
}

export enum OrderRequestType {
  MerchantOrder = 1,
  AgentPurchaseOrder,
}

export enum InvoiceStatus {
  Pending = 10,
  Checked = 20,
  Verified = 25,
  Paid = 30,
  Unpaid = 40,
}
